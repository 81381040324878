import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

Vue.use(VueRouter);

const routes = [

  // PUBLIC ROUTES

  {
    path: '/signup',
    name: 'Sign Up',
    component: () => import('@/views/public/SignUpView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Sign Up',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/public/LoginView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Login',
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/public/ForgotPasswordView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Forgot Password',
    },
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import('@/views/public/ResetPasswordView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Reset Password',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('@/views/public/MaintenanceView.vue'),
    meta: {
      public: true,
      owner: false,
      title: 'Maintenance',
    },
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    component: () => import('@/views/public/VerifyEmailView.vue'),
    meta: {
      public: true,
      owner: false,
      title: 'Verify Email',
    },
  },

  // PRIVATE ROUTES

  // Account not required for these routes:

  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('@/views/private/AccountsView.vue'),
    meta: {
      public: false,
      account: false,
      owner: false,
      title: 'Accounts',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/private/SettingsView.vue'),
    meta: {
      public: false,
      account: false,
      owner: false,
      title: 'Settings',
    },
  },
  {
    path: '/tasks-overview',
    name: 'Tasks Overview',
    component: () => import('@/views/private/TasksOverviewView.vue'),
    meta: {
      public: false,
      account: false,
      owner: false,
      title: 'Tasks Overview',
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/private/DashboardView.vue'),
    meta: {
      public: false,
      account: false,
      owner: false,
      title: 'Dashboard',
    },
  },

  // Account is required for these routes:

  {
    path: '/account/:accountId/',
    name: 'Account Dashboard',
    component: () => import('@/views/private/DashboardView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      title: 'Account Dashboard',
    },
  },
  {
    path: '/account/:accountId/overview',
    name: 'Overview',
    component: () => import('@/views/private/OverviewView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Overview',
    },
  },
  {
    path: '/account/:accountId/reports',
    name: 'Reports',
    component: () => import('@/views/private/ReportsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Reports',
    },
  },
  {
    path: '/account/:accountId/contacts',
    name: 'Contacts',
    component: () => import('@/views/private/ContactsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Contacts',
    },
  },
  {
    path: '/account/:accountId/contact/:contactId',
    name: 'Contact',
    component: () => import('@/views/private/ContactView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        contactId: Number(route.params.contactId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Contact',
    },
  },
  {
    path: '/account/:accountId/contacts-import',
    name: 'Contacts Import',
    component: () => import('@/views/private/ContactsImportView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Contacts Import',
    },
  },
  {
    path: '/account/:accountId/contact-lists',
    name: 'Contact Lists',
    component: () => import('@/views/private/ContactListsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Contact Lists',
    },
  },
  {
    path: '/account/:accountId/contact-list/:contactListId',
    name: 'Contact List',
    component: () => import('@/views/private/ContactListView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        contactListId: Number(route.params.contactListId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Contact List',
    },
  },
  {
    path: '/account/:accountId/contact-filter/:contactFilterId',
    name: 'Contact Filter',
    component: () => import('@/views/private/ContactFilterView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        contactFilterId: Number(route.params.contactFilterId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Contact Filter',
    },
  },
  {
    path: '/account/:accountId/contact-properties',
    name: 'Contact Properties',
    component: () => import('@/views/private/ContactPropertiesView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Contact Properties',
    },
  },
  {
    path: '/account/:accountId/companies',
    name: 'Companies',
    component: () => import('@/views/private/CompaniesView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Companies',
    },
  },
  {
    path: '/account/:accountId/company/:companyId',
    name: 'Company',
    component: () => import('@/views/private/CompanyView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        companyId: Number(route.params.companyId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Company',
    },
  },
  {
    path: '/account/:accountId/tags',
    name: 'Tags',
    component: () => import('@/views/private/TagsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Tags',
    },
  },
  {
    path: '/account/:accountId/tag/:tagId',
    name: 'Tag',
    component: () => import('@/views/private/TagView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        tagId: Number(route.params.tagId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Tag',
    },
  },
  {
    path: '/account/:accountId/notes',
    name: 'Notes',
    component: () => import('@/views/private/NotesView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Notes',
    },
  },
  {
    path: '/account/:accountId/note/:noteId',
    name: 'Note',
    component: () => import('@/views/private/NoteView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        noteId: Number(route.params.noteId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Note',
    },
  },
  {
    path: '/account/:accountId/task-boards',
    name: 'Task Boards',
    component: () => import('@/views/private/TaskBoardsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Task Boards',
    },
  },
  {
    path: '/account/:accountId/task-board/:taskBoardId',
    name: 'Task Board',
    component: () => import('@/views/private/TaskBoardView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        taskBoardId: Number(route.params.taskBoardId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Task Board',
    },
  },
  {
    path: '/account/:accountId/task-board-templates',
    name: 'Task Board Templates',
    component: () => import('@/views/private/TaskBoardTemplatesView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Task Board Templates',
    },
  },
  {
    path: '/account/:accountId/tasks',
    name: 'Tasks',
    component: () => import('@/views/private/TasksView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Tasks',
    },
  },
  {
    path: '/account/:accountId/task/:taskId',
    name: 'Task',
    component: () => import('@/views/private/TaskView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        taskId: Number(route.params.taskId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Task',
    },
  },
  {
    path: '/account/:accountId/workflows',
    name: 'Workflows',
    component: () => import('@/views/private/WorkflowsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Workflows',
    },
  },
  {
    path: '/account/:accountId/workflow-setup/:workflowId',
    name: 'Workflow Setup',
    component: () => import('@/views/private/WorkflowDraftView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        workflowId: Number(route.params.workflowId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Workflow',
    },
  },
  {
    path: '/account/:accountId/workflow-setup',
    name: 'Workflow Setup [new]',
    component: () => import('@/views/private/WorkflowDraftView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Workflow',
    },
  },
  {
    path: '/account/:accountId/workflow/:workflowId',
    name: 'Workflow',
    component: () => import('@/views/private/WorkflowView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        workflowId: Number(route.params.workflowId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Workflow',
    },
  },
  {
    path: '/account/:accountId/workflow-templates',
    name: 'WorkflowTemplates',
    component: () => import('@/views/private/WorkflowTemplatesView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Workflow Templates',
    },
  },
  {
    path: '/account/:accountId/workflow-template/:workflowTemplateId',
    name: 'Workflow Template',
    component: () => import('@/views/private/WorkflowTemplateView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        workflowTemplateId: Number(route.params.workflowTemplateId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Workflow Template',
    },
  },
  {
    path: '/account/:accountId/schedules',
    name: 'Schedules',
    component: () => import('@/views/private/SchedulesView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Schedules',
    },
  },
  {
    path: '/account/:accountId/prospects',
    name: 'Prospects',
    component: () => import('@/views/private/ProspectsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Prospects',
    },
  },
  {
    path: '/account/:accountId/prospect/:searchId',
    name: 'Prospect',
    component: () => import('@/views/private/ProspectView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        searchId: Number(route.params.searchId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'Prospect Search',
    },
  },
  {
    path: '/account/:accountId/linkedin',
    name: 'LinkedIn',
    component: () => import('@/views/private/linkedin/LinkedinView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn',
    },
  },
  {
    path: '/account/:accountId/linkedin/:linkedinId',
    name: 'LinkedIn Single',
    component: () => import('@/views/private/linkedin/LinkedinSingleView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        linkedinId: Number(route.params.linkedinId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Account',
    },
  },
  {
    path: '/account/:accountId/linkedin-conversations',
    name: 'LinkedIn Conversations',
    //component: () => import('@/views/private/linkedin/LinkedinConversationsView.vue'),
    component: () => import('@/views/private/linkedin/LinkedinConversationView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Conversations',
    },
  },
  {
    path: '/account/:accountId/linkedin-conversation/:conversationId',
    name: 'LinkedIn Conversation',
    component: () => import('@/views/private/linkedin/LinkedinConversationView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        conversationId: Number(route.params.conversationId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Conversation',
    },
  },
  {
    path: '/account/:accountId/linkedin-connections',
    name: 'LinkedIn Connections',
    component: () => import('@/views/private/linkedin/LinkedinConnectionsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Connections',
    },
  },
  {
    path: '/account/:accountId/linkedin-invitations-received',
    name: 'LinkedIn Invitations Received',
    component: () => import('@/views/private/linkedin/LinkedinInvitationsReceivedView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Invitations Received',
    },
  },
  {
    path: '/account/:accountId/linkedin-invitations-sent',
    name: 'LinkedIn Invitations Sent',
    component: () => import('@/views/private/linkedin/LinkedinInvitationsSentView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Invitations Sent',
    },
  },
  {
    path: '/account/:accountId/linkedin-company-invitations-sent',
    name: 'LinkedIn Company Invitations Sent',
    component: () => import('@/views/private/linkedin/LinkedinCompanyInvitationsSentView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Company Invitations Sent',
    },
  },
  {
    path: '/account/:accountId/linkedin-suggestions',
    name: 'LinkedIn Suggestions',
    component: () => import('@/views/private/linkedin/LinkedinSuggestionsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Suggestions',
    },
  },
  {
    path: '/account/:accountId/linkedin-views',
    name: 'LinkedIn Views',
    component: () => import('@/views/private/linkedin/LinkedinViewsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Views',
    },
  },
  {
    path: '/account/:accountId/linkedin-company-posts',
    name: 'LinkedIn Company Posts',
    component: () => import('@/views/private/linkedin/LinkedinCompanyPostsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Company Posts',
    },
  },
  {
    path: '/account/:accountId/linkedin-searches',
    name: 'LinkedIn Searches',
    component: () => import('@/views/private/linkedin/LinkedinSearchesView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Searches',
    },
  },
  {
    path: '/account/:accountId/linkedin-search/:searchId',
    name: 'LinkedIn Search',
    component: () => import('@/views/private/linkedin/LinkedinSearchView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        searchId: Number(route.params.searchId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Search',
    },
  },
  {
    path: '/account/:accountId/linkedin-groups',
    name: 'LinkedIn Groups',
    component: () => import('@/views/private/linkedin/LinkedinGroupsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Groups',
    },
  },
  {
    path: '/account/:accountId/linkedin-group/:linkedinGroupId',
    name: 'LinkedIn Group',
    component: () => import('@/views/private/linkedin/LinkedinGroupView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        linkedinGroupId: Number(route.params.linkedinGroupId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      title: 'LinkedIn Group',
    },
  },
  {
    path: '/account/:accountId/integrations',
    name: 'Integrations',
    component: () => import('@/views/private/integration/IntegrationsView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      requireRegular: true,
      title: 'Integrations',
    },
  },
  {
    path: '/account/:accountId/integrations/:integrationId',
    name: 'Integration',
    component: () => import('@/views/private/integration/IntegrationView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        integrationId: Number(route.params.integrationId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      requireRegular: true,
      title: 'Integration',
    },
  },
  {
    path: '/account/:accountId/webhooks',
    name: 'Webhooks',
    component: () => import('@/views/private/integration/WebhooksView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      requireRegular: true,
      title: 'Webhooks',
    },
  },
  {
    path: '/account/:accountId/webhooks/:webhookId',
    name: 'Webhook',
    component: () => import('@/views/private/integration/WebhookView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
        webhookId: Number(route.params.webhookId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      requireActive: true,
      requireRegular: true,
      title: 'Webhook',
    },
  },
  {
    path: '/account/:accountId/users',
    name: 'Users',
    component: () => import('@/views/private/UsersView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: true,
      title: 'Users',
    },
  },
  {
    path: '/account/:accountId/account',
    name: 'Account',
    component: () => import('@/views/private/AccountView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
      title: 'Account',
    },
  },
  {
    path: '/account/:accountId/billing',
    name: 'Billing',
    component: () => import('@/views/private/BillingView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: true,
      title: 'Billing',
    },
  },
  {
    path: '/account/:accountId/billing-alternative',
    name: 'Billing (alternative)',
    component: () => import('@/views/private/BillingAltView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: true,
      title: 'Billing',
    },
  },
  {
    path: '/account/:accountId/search',
    name: 'Global Search',
    component: () => import('@/views/private/SearchView.vue'),
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: true,
      title: 'Search',
    },
  },
  // Redirects for the account routes

  {
    path: '/overview',
    name: 'Overview [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/reports',
    name: 'Reports [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/contacts',
    name: 'Contacts [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/contact/:contactId',
    name: 'Contact [no_account]',
    props: function(route) {
      return {
        contactId: Number(route.params.contactId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/contacts-import',
    name: 'Contacts Import [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/contact-lists',
    name: 'Contact Lists [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/contact-list/:contactListId',
    name: 'Contact List [no_account]',
    props: function(route) {
      return {
        contactListId: Number(route.params.contactListId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/contact-properties',
    name: 'Contact Properties [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/companies',
    name: 'Companies [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/company/:companyId',
    name: 'Company [no_account]',
    props: function(route) {
      return {
        companyId: Number(route.params.companyId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/tags',
    name: 'Tags [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/tag/:tagId',
    name: 'Tag [no_account]',
    props: function(route) {
      return {
        tagId: Number(route.params.tagId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/notes',
    name: 'Notes [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/note/:noteId',
    name: 'Note [no_account]',
    props: function(route) {
      return {
        noteId: Number(route.params.noteId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/task-boards',
    name: 'Task Boards [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/task-board/:taskBoardId',
    name: 'Task Board [no_account]',
    props: function(route) {
      return {
        taskBoardId: Number(route.params.taskBoardId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/task-board-templates',
    name: 'Task Board Templates [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/tasks',
    name: 'Tasks [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/task/:taskId',
    name: 'Task [no_account]',
    props: function(route) {
      return {
        taskId: Number(route.params.taskId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/workflows',
    name: 'Workflows [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/workflow-setup/:workflowId',
    name: 'Workflow Setup [no_account]',
    props: function(route) {
      return {
        workflowId: Number(route.params.workflowId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/workflow/:workflowId',
    name: 'Workflow [no_account]',
    props: function(route) {
      return {
        workflowId: Number(route.params.workflowId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/workflow-templates',
    name: 'WorkflowTemplates [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/workflow-template/:workflowTemplateId',
    name: 'Workflow Template [no_account]',
    props: function(route) {
      return {
        workflowTemplateId: Number(route.params.workflowTemplateId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/schedules',
    name: 'Schedules [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/prospects',
    name: 'Prospects [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/prospect/:searchId',
    name: 'Prospect [no_account]',
    props: function(route) {
      return {
        searchId: Number(route.params.searchId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin',
    name: 'LinkedIn [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin/:linkedinId',
    name: 'LinkedIn Single [no_account]',
    props: function(route) {
      return {
        linkedinId: Number(route.params.linkedinId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-conversations',
    name: 'LinkedIn Conversations [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-conversation/:conversationId',
    name: 'LinkedIn Conversation [no_account]',
    props: function(route) {
      return {
        conversationId: Number(route.params.conversationId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-connections',
    name: 'LinkedIn Connections [no_account]',
    props: function(route) {
      return {
        accountId: Number(route.params.accountId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-invitations-received',
    name: 'LinkedIn Invitations Received [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-invitations-sent',
    name: 'LinkedIn Invitations Sent [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-suggestions',
    name: 'LinkedIn Suggestions [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-views',
    name: 'LinkedIn Views [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-company-invitations-sent',
    name: 'LinkedIn Company Invitations Sent [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-company-posts',
    name: 'LinkedIn Company Posts [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-searches',
    name: 'LinkedIn Searches [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-search/:searchId',
    name: 'LinkedIn Search [no_account]',
    props: function(route) {
      return {
        searchId: Number(route.params.searchId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-groups',
    name: 'LinkedIn Groups [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/linkedin-group/:linkedinGroupId',
    name: 'LinkedIn Group [no_account]',
    props: function(route) {
      return {
        linkedinGroupId: Number(route.params.linkedinGroupId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/integrations',
    name: 'Integrations [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/integrations/:integrationId',
    name: 'Integration [no_account]',
    props: function(route) {
      return {
        integrationId: Number(route.params.integrationId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/webhooks',
    name: 'Webhooks [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/webhooks/:webhookId',
    name: 'Webhook [no_account]',
    props: function(route) {
      return {
        webhookId: Number(route.params.webhookId),
      };
    },
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/users',
    name: 'Users [no_account]',
    meta: {
      public: false,
      account: true,
      owner: true,
    },
  },
  {
    path: '/account',
    name: 'Account [no_account]',
    meta: {
      public: false,
      account: true,
      owner: false,
    },
  },
  {
    path: '/billing',
    name: 'Billing [no_account]',
    meta: {
      public: false,
      account: true,
      owner: true,
    },
  },
  {
    path: '/billing-alternative',
    name: 'Billing (alternative) [no_account]',
    meta: {
      public: false,
      account: true,
      owner: true,
    },
  },
  {
    path: '/404',
    alias: '*',
    name: 'Page Not Found',
    component: () => import('@/views/public/PageNotFoundView.vue'),
    props: function(route) {
      return {
        redirected: Boolean(route.query.redirect),
      };
    },
    meta: {
      public: true,
      owner: false,
      title: 'Page Not Found',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('loadingRoute', true);

  if (to.name == 'Maintenance') {
    // Show Maintenance only in the maintenance mode
    if (store.state.isMaintenance) {
      next()
    } else {
      next('/')
    }
  } else if (store.state.isMaintenance) {
    // Maintenance mode - redirect to maintenance page
    next('/maintenance')
  } else if (to.meta.public && to.name !== 'Page Not Found') {
    // Public route - just proceed
    next()
  } else {
    // Private route - auth required
    if (store.getters.authenticated) {
      // Logged in
      if (to.meta.requireActive && !store.getters.isActive) {
        // Active account required - redirect to the billing page
        next('/billing')
      } else if (to.meta.requireRegular && store.getters.isBasic) {
        // At least Regular plan required - redirect to the billing page
        next('/billing')
      } else if (to.meta.account) {
        // Account required for this route

        // Here we will check if accounts are loaded,
        // and if an account id is set in the route.

        // The purpose of this is so it would be possible to share a link
        // both to an account-specific page, e.g. /account/13/contacts,
        // and to generic page, e.g. /contacts (in this case it should
        // auto-redirect to the account-specific page, e.g. /account/13/contacts
        // if accounts are loaded, or to the accounts page /accounts)

        if (!store.state.accounts) {
          // Accounts not found or not loaded - redirect to the accounts page
          next('/accounts')
        } else {
          // Accounts found & loaded - load the requested account
          var accountId = to.params.accountId;
          if (!accountId) {
            // Account not specified in the route
            // Redirect to the account-specific route
            if (store.state.account) accountId = store.state.account.id;
            else accountId = store.state.accounts[0].id;
            next('/account/' + accountId + to.path);
          } else {
            var account = store.state.accounts.find(x => x.id == accountId);
            if (!account) {
              // Account not found - redirect to the accounts page
              next('/accounts')
            } else if (to.meta.owner && !(account.owner.id == store.state.user.id)) {
              // Account found, but the route requires owner access
              // Redirect to the accounts page
              next('/')
            } else {
              // Account found & access confirmed - proceed
              next()
            }
          }
        }
      } else {
        // Account not required for this route - proceed
        next()
      }
    } else {
      // Not authenticated - redirect to login
      next('/login')
    }
  }
})

router.afterEach((to) => {
  store.dispatch('loadingRoute', false);

  // Update page title.
  document.title = to.meta.title ? to.meta.title + ' | Webwalker' : 'Webwalker';

  // Push page hit to Yandex Metrika.
  if (window.ym && window.Ya && window.Ya._metrika) {
    // For all installed Yandex Metrika counters.
    var counters = window.Ya._metrika.getCounters();
    for (const yc of counters) {
      if (!yc || !yc.id) continue;

      // Push page hit event.
      window.ym(yc.id, 'hit', window.location.href, {
        'title': document.title
      });
    }
  }
})

export default router
